import { useCallback, useEffect, useState } from "react";
import { CloseButton } from "shared/ui/buttons";
import { Alert, Text } from "./styled";

const Notification = (props) => {
  const {
    id,
    content,
    onClose,
    isCloseable,
    type = "success",
    delay = 10000,
    removeFromList,
  } = props;
  const [isVisible, setIsVisible] = useState(content);

  const closeNotification = useCallback(() => {
    setIsVisible(false);

    if (removeFromList) removeFromList(id);
    if (onClose) onClose();
  }, [id, removeFromList, onClose]);

  useEffect(() => {
    let notifierTimer;

    if (!isCloseable) {
      notifierTimer = setTimeout(() => closeNotification(), delay);
    }

    return () => {
      if (notifierTimer) clearTimeout(notifierTimer);
    };
  }, [isCloseable, closeNotification, delay]);

  if (!isVisible) return null;

  return (
    <Alert role="alert" isError={type === "error"}>
      <Text>{content}</Text>

      {isCloseable && <CloseButton onClick={closeNotification} />}
    </Alert>
  );
};

export default Notification;
