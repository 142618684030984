import styled, { css } from "styled-components";

export const Nav = styled.nav(
  ({ theme: { zIndex, colors } }) => css`
    position: sticky;
    left: 0;
    right: 0;
    top: 1.25rem;
    z-index: ${zIndex.aboveContent};
    display: flex;
    justify-content: space-between;
    border: 0.063rem solid ${colors.white};
    background-color: ${colors.black};
  `
);

export const DataSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 1.25rem;
  padding: 1rem;
`;

export const RightSection = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    display: flex;
    gap: 0.5rem;

    ${down(breakpoints.sm)} {
      flex-direction: column;
      padding: 0.8rem;
    }
  `
);

export const LogoSection = styled.div(
  ({ theme: { colors, down, breakpoints } }) => css`
    display: flex;
    align-items: center;
    padding: 1rem;
    border-right: 0.063rem solid ${colors.white};

    svg {
      width: 3.75rem;
    }

    ${down(breakpoints.sm)} {
      padding: 0.8rem;

      svg {
        width: 2rem;
      }
    }
  `
);
