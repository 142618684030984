import styled, { css } from "styled-components";

export const StyledButton = styled.button(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    color: ${colors.primary};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `
);
