import { StyledLink } from "./styled";

const Link = ({ href, children, ...restProps }) => {
  return (
    <StyledLink href={href} {...restProps}>
      {children}
    </StyledLink>
  );
};

export default Link;
