import { ThemeProvider } from "styled-components";

import NoWalletDetected from "components/NoWalletDetected";
import Tabs from "components/Tabs";
import Notifier from "components/Notifier";

import { NotificationProvider } from "context/notificationContext";
import { TokenProvider } from "context/tokenContext";
import { GlobalStyles, theme } from "shared/styles";

const App = () => {
  const isWalletDetected = window.ethereum === undefined;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <NotificationProvider>
        <TokenProvider>
          <Notifier />

          {isWalletDetected ? <NoWalletDetected /> : <Tabs />}
        </TokenProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
