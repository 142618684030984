import styled from "styled-components";

export const Title = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 1.5rem;
`;

export const Details = styled.p`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  margin: 0;
`;
