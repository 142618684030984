import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const MenuButton = styled.button(
  ({ theme: { down, breakpoints } }) => css`
    display: none;
    width: 2rem;
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;

    svg {
      width: 100%;
    }

    ${down(breakpoints.md)} {
      display: block;
    }
  `
);

export const List = styled.ul(
  ({ theme: { down, breakpoints, colors }, isMobile }) => css`
    gap: 1.25rem;
    ${isMobile
      ? css`
          position: absolute;
          top: 100%;
          display: none;
          flex-direction: column;
          min-width: 10rem;
          padding: 1rem;
          border: 0.063rem solid;
          background-color: ${colors.black};

          ${down(breakpoints.md)} {
            display: flex;
          }
        `
      : css`
          display: flex;

          ${down(breakpoints.md)} {
            display: none;
          }
        `}
  `
);

export const Item = styled.li(
  ({ isActiveTab }) => css`
    ${isActiveTab && "opacity: 0.6"}
  `
);
