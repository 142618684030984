import { useCallback, useEffect, useState } from "react";

import { useNotificationContext } from "context/notificationContext";
import { getToken, getProvider } from "shared/services/ethersService";

const useInitializeEthers = () => {
  const [provider, setProvider] = useState(null);
  const [token, setToken] = useState(null);
  const { addErrorNotification } = useNotificationContext();

  const initializeEthers = useCallback(async () => {
    try {
      const newProvider = getProvider();
      const newToken = getToken(newProvider);

      setProvider(newProvider);
      setToken(newToken);
    } catch (error) {
      addErrorNotification(error);
    }
  }, [addErrorNotification]);

  useEffect(() => {
    initializeEthers();
  }, [initializeEthers]);

  return { provider, token };
};

export default useInitializeEthers;
