import { useState, useRef } from "react";

import { useWalletContext } from "context/walletContext";
import { useAppSettingsContext } from "context/appSettingsContext";
import useClickOutside from "shared/hooks/useClickOutside";
import { ReactComponent as HamburgerIcon } from "shared/icons/hamburger.svg";

import { Wrapper, MenuButton, List, Item } from "./styled";

const MenuList = ({ activeTab, setActiveTab }) => {
  const { selectedAddress } = useWalletContext();
  const { isAppOwner } = useAppSettingsContext();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setMenuVisibility(false));

  const onClick = (event) => {
    event.preventDefault();
    setActiveTab(event.target.id);
  };

  const tabs = [
    {
      id: "projects-tab",
      text: "Projects",
      isVisible: true,
    },
    {
      id: "new-project-tab",
      text: "New Project",
      isVisible: selectedAddress,
    },
    {
      id: "settings-tab",
      text: "Settings",
      isVisible: selectedAddress && isAppOwner,
    },
  ];

  const toggleMenu = () => setMenuVisibility((prev) => !prev);

  const NavigationList = ({ isMobile }) => (
    <List ref={dropdownRef} isMobile={isMobile}>
      {tabs.map(
        ({ isVisible, id, text }) =>
          isVisible && (
            <Item isActiveTab={activeTab === id} key={id}>
              <a id={id} href="/" onClick={onClick} aria-current="page">
                {text}
              </a>
            </Item>
          )
      )}
    </List>
  );

  return (
    <Wrapper>
      <MenuButton onClick={toggleMenu} title="Toggle menu">
        <HamburgerIcon />
      </MenuButton>

      <NavigationList />
      {menuVisibility && <NavigationList isMobile />}
    </Wrapper>
  );
};

export default MenuList;
