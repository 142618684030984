import { useMemo } from "react";
import useConnectWallet from "./useConnectWallet";
import WalletContext from "./WalletContext";

const WalletProvider = ({ children }) => {
  const {
    signedToken,
    selectedAddress,
    connectWallet,
    networkError,
    setNetworkError,
    isConnected,
  } = useConnectWallet();

  const context = useMemo(
    () => ({
      signedToken,
      selectedAddress,
      connectWallet,
      networkError,
      setNetworkError,
      isConnected,
    }),
    [
      signedToken,
      selectedAddress,
      connectWallet,
      networkError,
      setNetworkError,
      isConnected,
    ]
  );

  return (
    <WalletContext.Provider value={context}>{children}</WalletContext.Provider>
  );
};

export default WalletProvider;
