import { useMemo } from "react";
import TokenContext from "./TokenContext";
import useInitializeEthers from "./useInitializeEthers";

const TokenProvider = ({ children }) => {
  const { provider, token } = useInitializeEthers();

  const context = useMemo(() => ({ token, provider }), [token, provider]);

  return (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
  );
};

export default TokenProvider;
