import { useState } from "react";

import Menu from "components/Menu";
import WalletInfo from "components/WalletInfo";
import CreateProjectForm from "components/CreateProjectForm";
import ProjectList from "components/ProjectList";
import Settings from "components/Settings";

import { WalletProvider } from "context/walletContext";
import { AppSettingsProvider } from "context/appSettingsContext";
import { Container, Content } from "./styled";

const Tabs = () => {
  const projectsTab = "projects-tab";
  const newProjectTab = "new-project-tab";
  const settingsTab = "settings-tab";
  const [activeTab, setActiveTab] = useState(projectsTab);

  return (
    <WalletProvider>
      <AppSettingsProvider>
        <Container>
          <Menu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            rightComponent={<WalletInfo />}
          />

          <Content>
            {activeTab === projectsTab && <ProjectList />}
            {activeTab === newProjectTab && (
              <CreateProjectForm
                goToProjectsTab={() => setActiveTab(projectsTab)}
              />
            )}
            {activeTab === settingsTab && <Settings />}
          </Content>
        </Container>
      </AppSettingsProvider>
    </WalletProvider>
  );
};

export default Tabs;
