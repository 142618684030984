import styled, { css } from "styled-components";

export const StyledInput = styled.input(
  ({ theme: { colors } }) => css`
    padding: 1rem;
    color: ${colors.white};
    border: 0.063rem solid ${colors.white};
    background-color: transparent;

    &:focus {
      outline: none;
      border-color: ${colors.primary};
    }
  `
);
