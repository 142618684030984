import styled from "styled-components";

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.75rem 1.5rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.white};
`;

export const Description = styled.p`
  margin: 0;
`;

export const Outcome = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white60};
`;
