import ProjectCard from "components/ProjectCard";
import { NoProjectsMessage, Projects } from "./styled";
import useGetProjects from "./useGetProjects";

const ProjectList = () => {
  const { projects } = useGetProjects();

  if (!projects.length) {
    return <NoProjectsMessage>There are no projects</NoProjectsMessage>;
  }

  return (
    <Projects>
      {projects.map((project) => (
        <ProjectCard project={project} key={project.uuid} />
      ))}
    </Projects>
  );
};

export default ProjectList;
