import { Contract, providers, utils } from "ethers";
import truncateEthAddress from "truncate-eth-address";

import hardhatContractAddress from "contracts/hardhat/dai-address.json";
import hardhatContractAbi from "contracts/hardhat/dai-abi.json";
import goerliContractAddress from "contracts/goerli/dai-address.json";
import goerliContractAbi from "contracts/goerli/dai-abi.json";

export const getProvider = () => new providers.Web3Provider(window.ethereum);

export const getToken = (provider) => {
  let contract;
  if (process.env.REACT_APP_ETHEREUM_NETWORK === 'goerli') {
    contract = new Contract(goerliContractAddress.Address, goerliContractAbi, provider);
  } else {
    contract = new Contract(hardhatContractAddress.Address, hardhatContractAbi.abi, provider);
  }
  return contract;
}

export const formatEther = (data) => utils.formatEther(data);

export const parseEther = (data) => utils.parseEther(data);

export const getAddress = (address) => utils.getAddress(address);

export const truncateEtherAddress = (address) => truncateEthAddress(address);
