import { css } from "styled-components";

export const buttonStyles = ({ theme, customStyles }) => {
  const { colors } = theme;

  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.63rem 1rem;
    color: ${colors.black};
    cursor: pointer;
    user-select: none;
    border: 0;
    background-color: ${colors.primary};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 1rem;
    font-weight: 700;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:focus,
    &:hover {
      background-color: ${colors.primaryDark};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.25rem rgba(0 0 0 / 15%);
    }

    ${customStyles && customStyles(theme)}
  `;
};
