import styled, { css } from "styled-components";

export const StyledButton = styled.button(
  ({ theme: { zIndex, colors } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${zIndex.contentMin};
    width: 1rem;
    height: 1rem;
    margin: 0.3rem 0.63rem;
    padding: 0.1rem;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: bold;
    line-height: 0.8;

    &:hover,
    &:focus {
      background-color: ${colors.black30};
    }
  `
);
