import styled, { css } from "styled-components";

export const StyledSpinner = styled.span(
  ({ theme: { colors }, isPrimary, isSmall }) => css`
    display: inline-block;
    width: ${isSmall ? "1rem" : "2rem"};
    height: ${isSmall ? "1rem" : "2rem"};
    color: ${isPrimary ? colors.primary : "inherit"};
    border: ${isSmall ? "0.13rem" : "0.25rem"} solid currentcolor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;

    @keyframes spinner {
      100% {
        transform: rotate(360deg);
      }
    }
  `
);
