import { useContext } from "react";
import AppSettingsContext from "./AppSettingsContext";

const useAppSettingsContext = () => {
  const appSettingsContext = useContext(AppSettingsContext);

  return appSettingsContext;
};

export default useAppSettingsContext;
