import { useContext } from "react";
import TokenContext from "./TokenContext";

const useTokenContext = () => {
  const { provider, token } = useContext(TokenContext);

  return {
    provider,
    token,
  };
};

export default useTokenContext;
