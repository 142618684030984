import { buttonStyles } from "shared/styles/buttonStyles";
import styled, { css } from "styled-components";

export const StyledLink = styled.a(
  ({ theme, isOutline, isRounded, isSmall }) => css`
    ${buttonStyles({ theme, isOutline, isRounded, isSmall })}

    &:hover,
    &:focus {
      text-decoration: none;
    }
  `
);
