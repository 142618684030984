import FieldWrapper from "../FieldWrapper";
import Label from "../Label";
import { StyledInput } from "./styled";

const InputField = ({
  id,
  type = "text",
  name,
  value,
  onChange,
  label,
  ...restProps
}) => {
  return (
    <FieldWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}

      <StyledInput
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        {...restProps}
      />
    </FieldWrapper>
  );
};

export default InputField;
