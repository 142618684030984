import { useMemo } from "react";
import useAppSettings from "./useAppSettings";
import AppSettingsContext from "./AppSettingsContext";

const AppSettingsProvider = ({ children }) => {
  const {
    appBalance,
    appFeePercentage,
    appWithdrawalAddress,
    isAppOwner,
    error,
    loading,
  } = useAppSettings();

  const context = useMemo(
    () => ({
      appBalance,
      appFeePercentage,
      appWithdrawalAddress,
      isAppOwner,
      error,
      loading,
    }),
    [appBalance, appFeePercentage, appWithdrawalAddress, isAppOwner, error, loading]
  );

  return (
    <AppSettingsContext.Provider value={context}>{children}</AppSettingsContext.Provider>
  );
};

export default AppSettingsProvider;
