import { Link } from "shared/ui/links";
import { Container, Wrapper } from "./styled";

const NoWalletDetected = () => {
  return (
    <Container>
      <Wrapper>
        <h1>No Ethereum wallet was detected.</h1>
        <h2>
          Please install{" "}
          <Link
            href="http://metamask.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            MetaMask
          </Link>
          .
        </h2>
      </Wrapper>
    </Container>
  );
};

export default NoWalletDetected;
