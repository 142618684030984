import { useWalletContext } from "context/walletContext";
import OutcomeForm from "components/OutcomeForm";

import CardHeader from "./CardHeader";
import Actions from "./Actions";
import { Card, Description, Outcome } from "./styled";

const ProjectCard = ({ project }) => {
  const { selectedAddress, signedToken } = useWalletContext();
  const { status, owner, contractorAddress, outcome, description, uuid } =
    project;

  const isOwner = selectedAddress === owner;
  const isContractor = selectedAddress === contractorAddress;
  const canApply = selectedAddress && status === 0 && !isOwner;
  const canSubmitOutcome = selectedAddress && status === 1 && isContractor;
  const canReviewOutcome = selectedAddress && status === 2 && isOwner;

  return (
    <Card>
      <CardHeader project={project} />

      {description && <Description>{description}</Description>}

      {outcome && <Outcome>{outcome}</Outcome>}

      {canSubmitOutcome && (
        <OutcomeForm project={project} signedToken={signedToken} />
      )}

      <Actions
        uuid={uuid}
        signedToken={signedToken}
        canApply={canApply}
        canReviewOutcome={canReviewOutcome}
      />
    </Card>
  );
};

export default ProjectCard;
