import { StyledButton } from "./styled";

const CloseButton = ({ ...restProps }) => {
  return (
    <StyledButton type="button" aria-label="Close" title="Close" {...restProps}>
      &#x2715;
    </StyledButton>
  );
};

export default CloseButton;
