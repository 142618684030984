import { useCallback, useEffect, useState } from "react";

import { BigNumber } from "ethers";

import { useTokenContext } from "context/tokenContext";
import { useWalletContext } from "context/walletContext";

const useAppSettings = () => {
  const { token } = useTokenContext();
  const { selectedAddress } = useWalletContext();
  const [appBalance, setAppBalance] = useState(null);
  const [appFeePercentage, setAppFeePercentage] = useState(5);
  const [appWithdrawalAddress, setAppWithdrawalAddress] = useState(null);
  const [isAppOwner, setIsAppOwner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const refreshAppBalance = useCallback(async (result) => {
    setLoading(true);
    try {
      setAppBalance(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshAppSettings = useCallback(async (result) => {
    setLoading(true);
    try {
      setAppFeePercentage(BigNumber.from(result["feePercentage"]).toNumber());
      setAppWithdrawalAddress(result["withdrawalAddress"]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const initAppSettings = useCallback(async () => {
    setLoading(true);
    try {
      const appBalance = await token.getBalance();
      const owner = await token.owner();
      const appSettings = await token.appSettings();
      const isOwner = owner === selectedAddress;

      setAppBalance(appBalance);
      setAppFeePercentage(BigNumber.from(appSettings["feePercentage"]).toNumber());
      setAppWithdrawalAddress(appSettings["withdrawalAddress"]);
      setIsAppOwner(isOwner);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [token, selectedAddress]);

  useEffect(() => {
    if (token) {
      initAppSettings();

      token.on("appBalanceUpdated", refreshAppBalance);
      token.on("appSettingsUpdated", refreshAppSettings);
    }
  }, [token, initAppSettings, refreshAppBalance, refreshAppSettings]);

  return {
    appBalance,
    appFeePercentage,
    appWithdrawalAddress,
    isAppOwner,
    error,
    loading,
  };
};

export default useAppSettings;
