import { useState } from "react";
import { useNotificationContext } from "context/notificationContext";

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const useTransaction = (successCallback, failCallback) => {
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addErrorNotification } = useNotificationContext();

  const transactionCallback = (txHash) => {
    setCurrentTransaction(txHash);
    if (successCallback) successCallback();
  };

  const errorCallback = (error) => {
    addErrorNotification(error);
    if (failCallback) failCallback(error);
  };

  const runTransaction = async (transaction) => {
    try {
      setLoading(true);
      const receipt = await transaction.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }

      transactionCallback(transaction.hash);
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      errorCallback(error);
    } finally {
      setCurrentTransaction(null);
      setLoading(false);
    }
  };

  return {
    loading,
    currentTransaction,
    setCurrentTransaction,
    runTransaction,
  };
};

export default useTransaction;
