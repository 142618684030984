import { useState } from "react";

import useTransaction from "shared/hooks/useTransaction";
import { Button } from "shared/ui/buttons";
import { TextAreaField } from "shared/ui/formElements";
import { Form } from "./styled";

const OutcomeForm = ({ project, signedToken }) => {
  const { loading, runTransaction } = useTransaction();
  const [outcome, setOutcome] = useState("");

  const submitProjectOutcome = async () => {
    const transaction = await signedToken.submitProjectOutcome(
      project.uuid,
      outcome
    );
    await runTransaction(transaction);
  };

  const onChange = (event) => {
    setOutcome(event.target.value);
  };

  return (
    <Form>
      <TextAreaField
        id="outcome"
        name="outcome"
        rows="3"
        value={outcome}
        onChange={onChange}
        placeholder="Write your outcome here"
        required
      />

      <Button
        onClick={submitProjectOutcome}
        disabled={loading}
        loading={loading}
      >
        Submit
      </Button>
    </Form>
  );
};

export default OutcomeForm;
