import { ReactComponent as LogoIcon } from "shared/icons/logo.svg";
import MenuList from "./MenuList";
import { Nav, LogoSection, DataSection, RightSection } from "./styled";

const Menu = ({ activeTab, setActiveTab, rightComponent }) => {
  return (
    <Nav>
      <LogoSection>
        <LogoIcon />
      </LogoSection>

      <DataSection>
        <MenuList activeTab={activeTab} setActiveTab={setActiveTab} />

        <RightSection>{rightComponent}</RightSection>
      </DataSection>
    </Nav>
  );
};

export default Menu;
