import styled, { css } from "styled-components";

export const Alert = styled.div(
  ({ theme: { colors }, isError }) => css`
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    color: ${colors.black};
    background-color: ${isError ? colors.primary : colors.white};
  `
);

export const Text = styled.div`
  margin-right: 1.25rem;
`;
