import { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";

import { useTokenContext } from "context/tokenContext";

const useGetProjects = () => {
  const { token } = useTokenContext();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadProjects = useCallback(async () => {
    if (!token) return;
    setLoading(true);

    try {
      const projectsCount = await token.projectsCount();
      const projects = [];

      for (let i = 0; i < projectsCount; i++) {
        const project = await token.projects(i);
        projects[i] = project;
      }

      setProjects(projects);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const projectUpdated = useCallback(
    async (result) => {
      setLoading(true);
      try {
        const index = BigNumber.from(result).toNumber();
        const project = await token.projects(index);

        setProjects((prevProjects) =>
          prevProjects.map((prj, i) => {
            if (i === index) return project;
            return prj;
          })
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    if (token) {
      loadProjects();

      token.on("projectCreated", loadProjects);
      token.on("projectUpdated", projectUpdated);
    }
  }, [token, loadProjects, projectUpdated]);

  return {
    projects,
    setProjects,
    error,
    loading,
  };
};

export default useGetProjects;
