import Spinner from "shared/ui/Spinner";
import { StyledButton } from "./styled";

const Button = ({
  type = "button",
  disabled,
  loading,
  onClick,
  customStyles,
  children,
  ...restProps
}) => {
  return (
    <StyledButton
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...restProps}
    >
      {loading ? (
        <>
          <Spinner isSmall />
          &nbsp;Loading
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
