import styled from "styled-components";

export const Container = styled.div`
  max-width: 73.75rem;
  margin: 0 auto;
  padding: 0 1.25rem;
`;

export const Content = styled.div`
  margin: 3.13rem 0;
`;
