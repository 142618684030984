import FieldWrapper from "../FieldWrapper";
import Label from "../Label";
import { StyledTextarea } from "./styled";

const TextAreaField = ({ id, name, value, onChange, label, ...restProps }) => {
  return (
    <FieldWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}

      <StyledTextarea
        rows="5"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        {...restProps}
      />
    </FieldWrapper>
  );
};

export default TextAreaField;
