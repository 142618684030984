import { useContext } from "react";
import WalletContext from "./WalletContext";

const useWalletContext = () => {
  const walletContext = useContext(WalletContext);

  return walletContext;
};

export default useWalletContext;
