import useTransaction from "shared/hooks/useTransaction";
import { Button, ButtonAsLink } from "shared/ui/buttons";
import Spinner from "shared/ui/Spinner";
import { Wrapper } from "./styled";

const Actions = ({ uuid, signedToken, canApply, canReviewOutcome }) => {
  const { loading, runTransaction } = useTransaction();

  const acceptProject = async () => {
    const transaction = await signedToken.submitAcceptOutcome(uuid);
    runTransaction(transaction);
  };

  const rejectProject = async () => {
    const transaction = await signedToken.submitRejectOutcome(uuid);
    runTransaction(transaction);
  };

  const applyProject = async () => {
    const transaction = await signedToken.applyToProject(uuid);
    runTransaction(transaction);
  };

  if (canApply) {
    return (
      <ButtonAsLink onClick={applyProject} disabled={loading} loading={loading}>
        Apply
      </ButtonAsLink>
    );
  }

  if (canReviewOutcome) {
    return (
      <Wrapper>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Button onClick={acceptProject}>Accept</Button>

            <Button onClick={rejectProject}>Reject</Button>
          </>
        )}
      </Wrapper>
    );
  }
};

export default Actions;
