import { StyledSpinner } from "./styled";

const Spinner = ({ isPrimary, isSmall, ...props }) => {
  return (
    <StyledSpinner
      role="status"
      isPrimary={isPrimary}
      isSmall={isSmall}
      {...props}
    />
  );
};

export default Spinner;
