import styled, { css } from "styled-components";

export const Projects = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3.75rem;

    ${down(breakpoints.lg)} {
      grid-template-columns: 1fr 1fr;
    }

    ${down(breakpoints.sm)} {
      grid-template-columns: 1fr;
    }
  `
);

export const NoProjectsMessage = styled.h2`
  margin: 0 auto;
  font-size: 1.5rem;
`;
