import { css } from "styled-components";

export const baseLinkStyles = ({ theme, customStyles }) => css`
  display: inline-flex;
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.15s ease-in-out;
  font-size: inherit;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }

  ${customStyles && customStyles(theme)}
`;

export const primaryLinkStyles = ({ theme }) => css`
  ${baseLinkStyles(theme)}
  color: ${theme.colors.primary};
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;
