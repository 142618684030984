import { useCallback, useEffect, useState } from "react";

import { useTokenContext } from "context/tokenContext";
import { useNotificationContext } from "context/notificationContext";
import { getAddress } from "shared/services/ethersService";
import { IS_WALLET_CONNECTED } from "shared/constants/localStorageKeys";

const { REACT_APP_ETHEREUM_NETWORK, REACT_APP_ETHEREUM_NETWORK_ID } =
  process.env;

const useConnectWallet = () => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [signedToken, setSignedToken] = useState(null);
  const [isErrorExist, setIsErrorExist] = useState(false);

  const { token, provider } = useTokenContext();
  const { addErrorNotification } = useNotificationContext();

  const isConnected = useCallback(
    () => localStorage.getItem(IS_WALLET_CONNECTED) === "true",
    []
  );

  const initialize = useCallback(
    (userAddress) => {
      const address = getAddress(userAddress);
      const signer = provider?.getSigner();

      setSelectedAddress(address);
      setSignedToken(token?.connect(signer));
      if (address && !isConnected()) {
        localStorage.setItem(IS_WALLET_CONNECTED, true);
      }
    },
    [provider, token, isConnected]
  );

  const checkNetwork = useCallback(() => {
    if (window.ethereum.networkVersion === REACT_APP_ETHEREUM_NETWORK_ID) {
      return true;
    }

    addErrorNotification({
      content: `Please connect Metamask to ${REACT_APP_ETHEREUM_NETWORK}`,
      isCloseable: true,
    });
    setIsErrorExist(true);
    return false;
  }, [addErrorNotification]);

  const resetState = () => {
    setSelectedAddress(null);
    setSignedToken(null);
    setIsErrorExist(null);
  };

  const connectWallet = useCallback(async () => {
    const [address] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (!checkNetwork()) return;

    initialize(address);
  }, [checkNetwork, initialize]);

  useEffect(() => {
    if (!selectedAddress && isConnected()) {
      connectWallet();
    }
  }, [connectWallet, selectedAddress, isConnected]);

  useEffect(() => {
    const onAccountsChanged = ([newAddress]) => {
      if (newAddress === undefined) {
        localStorage.setItem(IS_WALLET_CONNECTED, false);
        return resetState();
      }
      initialize(newAddress);
    };

    window.ethereum.on("accountsChanged", onAccountsChanged);
    window.ethereum.on("chainChanged", resetState);

    return () => {
      window.ethereum.removeListener("accountsChanged", onAccountsChanged);
      window.ethereum.removeListener("chainChanged", resetState);
    };
  }, [initialize]);

  return {
    signedToken,
    selectedAddress,
    connectWallet,
    isErrorExist,
    isConnected,
  };
};

export default useConnectWallet;
