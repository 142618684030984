import {
  formatEther,
  truncateEtherAddress,
} from "shared/services/ethersService";
import projectStatusHumanized from "shared/utils/projectStatus";
// import getStatusColorClass from "shared/utils/getStatusColorClass";
import StatusBadge from "shared/ui/StatusBadge";

import { Title, Details } from "./styled";

const CardHeader = ({ project }) => {
  const { name, ethAmount, status, owner, contractorAddress } = project;
  const unknownAddress = "0x0000000000000000000000000000000000000000";
  const statusName = projectStatusHumanized(status);
  // const statusColorName = getStatusColorClass(statusName);

  return (
    <>
      <StatusBadge status={statusName}>{statusName}</StatusBadge>

      <Title>
        {name} ({formatEther(ethAmount)} ETH)
      </Title>

      <div>
        <Details>
          <span>Owner </span>
          <strong>{truncateEtherAddress(owner)}</strong>
        </Details>

        {contractorAddress !== unknownAddress && (
          <Details>
            <span>Contractor </span>
            <strong>{truncateEtherAddress(contractorAddress)}</strong>
          </Details>
        )}
      </div>
    </>
  );
};

export default CardHeader;
