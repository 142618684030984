import { createGlobalStyle } from "styled-components";
import { H1, H2, H3 } from "./headersStyles";

const GlobalStyle = createGlobalStyle`
  body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    background-color: black;
    color: white;
    font-family: Inconsolata, sans-serif;
    font-size: 1rem;
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline
    }
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h1 {
    ${H1}
  }

  h2 {
    ${H2}
  }

  h3 {
    ${H3}
  }
`;

export default GlobalStyle;
