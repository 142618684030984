import { useWalletContext } from "context/walletContext";
import { truncateEtherAddress } from "shared/services/ethersService";
import { Button } from "shared/ui/buttons";

const WalletInfo = () => {
  const { selectedAddress, isErrorExist, connectWallet, isConnected } =
    useWalletContext();

  if (selectedAddress) {
    return (
      <>
        <span>Logged in as</span>
        <strong>{truncateEtherAddress(selectedAddress)}</strong>
      </>
    );
  }

  return (
    !isErrorExist &&
    !isConnected() && <Button onClick={connectWallet}>Connect Wallet</Button>
  );
};

export default WalletInfo;
