import { css } from "styled-components";

const commonStyles = (isCommon) => css`
  margin: 0 0 0.9rem;
  font-family: ${isCommon ? "Inconsolata" : "Montserrat"}, serif;
  font-weight: 800;
`;

export const H1 = () =>
  css`
    ${commonStyles()}
    font-size: 2rem;
  `;

export const H2 = (isCommon) =>
  css`
    ${commonStyles(isCommon)}
    font-size: 1.5rem;
  `;

export const H3 = (isCommon) =>
  css`
    ${commonStyles(isCommon)}
    font-size: 1.25rem;
  `;
