import { useState } from "react";

import { useWalletContext } from "context/walletContext";
import { parseEther } from "shared/services/ethersService";
import useTransaction from "shared/hooks/useTransaction";

import { Button } from "shared/ui/buttons";
import { Form, InputField, TextAreaField } from "shared/ui/formElements";

const CreateProjectForm = ({ goToProjectsTab }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("0");

  const { loading, runTransaction } = useTransaction(goToProjectsTab);
  const { signedToken } = useWalletContext();

  const createProject = async () => {
    const transaction = await signedToken.createProject(name, description, {
      value: parseEther(amount),
    });

    runTransaction(transaction);
  };

  return (
    <>
      <h1>Create Project</h1>

      <Form maxWidth="37.5rem">
        <InputField
          id="name"
          name="name"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />

        <TextAreaField
          id="description"
          name="description"
          label="Description"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          required
        />

        <InputField
          id="amount"
          type="number"
          name="amount"
          label="Eth Amount"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
          required
        />

        <Button onClick={createProject} disabled={loading} loading={loading}>
          Create
        </Button>
      </Form>
    </>
  );
};

export default CreateProjectForm;
