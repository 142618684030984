import { useState } from "react";
import useTransaction from "shared/hooks/useTransaction";

import { useAppSettingsContext } from "context/appSettingsContext";
import { useWalletContext } from "context/walletContext";

import { formatEther } from "shared/services/ethersService";

import { Button } from "shared/ui/buttons";
import { Form, InputField } from "shared/ui/formElements";

const Settings = () => {
  const { appBalance, appFeePercentage, appWithdrawalAddress } =
    useAppSettingsContext();

  const [feePercentage, setFeePercentage] = useState(appFeePercentage);
  const [withdrawalAddress, setWithdrawalAddress] =
    useState(appWithdrawalAddress);

  const { loading, runTransaction } = useTransaction();
  const { signedToken } = useWalletContext();

  const updateSettings = async () => {
    const transaction = await signedToken.updateSettings(
      feePercentage,
      withdrawalAddress
    );

    runTransaction(transaction);
  };

  return (
    <>
      <h1>Application Settings</h1>

      <h3>
        AppBalance: <strong>{formatEther(appBalance)}</strong> ETH
      </h3>

      <Form>
        <InputField
          id="app_fee_percantage"
          type="number"
          name="app_fee_percantage"
          label="Application Fee"
          value={feePercentage}
          onChange={(event) => setFeePercentage(event.target.value)}
          required
        />

        <InputField
          id="withdrawal_address"
          name="withdrawal_address"
          label="Withdrawal Address"
          value={withdrawalAddress}
          onChange={(event) => setWithdrawalAddress(event.target.value)}
          required
        />

        <Button onClick={updateSettings} disabled={loading} loading={loading}>
          Update
        </Button>
      </Form>
    </>
  );
};

export default Settings;
